import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Error = styled.div`
  font-size: 22px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
`;

const App = React.memo(() => <Error>Something went wrong. Please try refreshing the page.</Error>);

const wrapperId = '__loading-error-root__';

const render = props => {
  const { container } = props;

  const wrapper: HTMLDivElement =
    container.querySelector(`#${wrapperId}`) || document.createElement('div');
  wrapper.id = wrapperId;
  container.appendChild(wrapper);
  ReactDOM.render(<App />, wrapper);
};

export async function bootstrap() {
  // do nothing
}

export async function mount(props) {
  render(props);
}

export async function unmount(props) {
  const { container } = props;

  ReactDOM.unmountComponentAtNode(
    container ? container.querySelector(`#${wrapperId}`) : document.querySelector(`#${wrapperId}`),
  );
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}
